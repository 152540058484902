.notification-badge-container {
	width: 400px;
	max-height: 559px;
	overflow: auto;
	padding: 0;
}

.notification-badge-header-container {
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #ccc;
}

.notification-badge-header {
	font-size: 20px;
	font-weight: bold;
}

.notification-button-read {
	padding: 16px;
	display: flex;
	flex-direction: row;
	opacity: 0.5;
	background-color: #fff;
	cursor: pointer;
}

.notification-button-read:hover {
	/* TODO */
}

.notification-button-unread {
	padding: 16px;
	display: flex;
	flex-direction: row;
	background-color: rgba(242, 243, 255, 1);
	cursor: pointer;
}

.notification-button-unread:hover {
	/* TODO */
}

.initial-name-circle {
	margin-right: 12px;
	height: 32px;
	width: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	line-height: 0;
	font-family: "Roboto";
	font-size: 20px;
	color: rgba(255, 255, 255, 1);
	border-radius: 16px;
	background-color: rgba(177, 182, 255, 1);
}

.notification-message {
	flex: 1;
}

.notification-text {
	font-family: "Noto Sans";
	font-size: 16px;
	color: rgba(51, 51, 51, 1);
}

.notification-detail-time {
	font-family: "Noto Sans";
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
}

.no-notification-container {
	color: #999999;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}
